import React from 'react'
import { StaticQuery, graphql, Link as BasicLink } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import backgroundImage from './background.jpg'

const Element = styled.div`
  background: ${({ background }) =>
    background
      ? `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${backgroundImage}) no-repeat;`
      : 'none'};
  background-position: center;
  background-size: cover;
  margin-bottom: 0;
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 1rem 0rem;
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
`

const Logo = styled(Img)`
  height: 5rem;
  width: 5rem;
  margin: 0 1rem 0 0;
`

const Link = styled(BasicLink)`
  color: white;
  text-decoration: none;
`

const SubTitle = styled.div`
  font-size: 1rem;
  color: white;
  font-weight: 300;
`

const Header = ({ background = true }) => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "logo.png" }) {
          id
          childImageSharp {
            id
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => {
      const {
        file: { childImageSharp: logo },
      } = data
      return (
        <Element background={background}>
          <Container>
            <Logo fluid={logo.fluid} alt="" />
            <h1 style={{ margin: 0 }}>
              <Link to="/">JULIUS KLENGEL AKADEMIE</Link>
              <SubTitle>
                Musikalische Ausbildung – Violine, Violoncello, Musiktheorie
              </SubTitle>
            </h1>
          </Container>
        </Element>
      )
    }}
  />
)

export default Header
