import React from 'react'
import { Helmet } from 'react-helmet'

import logo from 'images/logo.png'

const SEO = props => {
  const { data = {}, jsonld = false } = props
  // Default values
  const { title = null } = data
  const {
    description = 'Schüler, Studenten, Eltern, Kinder, Profis, Amateure, Geiger, Cellisten – hier gibt es für jeden etwas zu entdecken!',
  } = data
  const { keywords = 'akademie, klengel, violine, violoncello, klassik' } = data
  const { path = '' } = data
  const rootUrl = 'https://www.julius-klengel-akademie.de'
  // schema.org via JSON-LD
  let schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: rootUrl,
      name: title,
    },
  ]

  if (jsonld) {
    schemaOrgJSONLD.push({
      '@context': 'http://schema.org',
      ...jsonld,
    })
  }

  return (
    <Helmet
      titleTemplate="%s | Julius Klengel Akademie"
      defaultTitle="Julius Klengel Akademie"
    >
      <html lang="de" />
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={logo} />
      {data.image ? <meta name="og:image" content={data.image.url} /> : null}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@klengelmusic" />
      <meta name="twitter:image" content={logo} />
      <link rel="canonical" href={`${rootUrl}${path}`} />
      <link rel="image_src" href={`${rootUrl}${logo}`} />
      <link rel="icon" type="image/png" href={logo} />
      <meta
        name="google-site-verification"
        content="oZ5erS2SRr_Fi3YeS1yglRfexM_Fftx33RKMO-yWsTY"
      />
    </Helmet>
  )
}

export default SEO
