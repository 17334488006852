import React from 'react'
import { Link as BasicLink } from 'gatsby'
import styled from 'styled-components'
import { Container } from 'unstyled'

const Element = styled.div`
  background-color: #333;
  padding: 1rem 0;
`

const Link = styled(BasicLink)`
  color: white;
  text-decoration: none;
  margin-right: 1rem;
`

const Footer = () => (
  <Element>
    <Container>
      <Link to="/impressum">Impressum</Link>
      <Link to="/datenschutz">Datenschutz</Link>
    </Container>
  </Element>
)

export default Footer
