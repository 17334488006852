import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Container } from 'unstyled'
import presets from 'utils/presets'

const Component = styled.div`
  display: none;
  border-bottom: 1px solid silver;
  margin-bottom: 1rem;
  ${presets.Tablet} {
    display: inherit;
  }
`

const Menu = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: space-between;
  a {
    color: ${({ inverted }) => (inverted ? 'white' : 'inherit')};
  }
`
const Item = styled(Link)`
  margin-right: 1rem;
  text-decoration: none;
  padding: 1rem;
`

const Navigation = ({ inverted }) => (
  <Component>
    <Container>
      <Menu inverted={inverted}>
        <Item to="/">Home</Item>
        <Item to="/ueber-uns">Über uns</Item>
        <Item to="/team">Team</Item>
        <Item to="/veranstaltungen">Veranstaltungen</Item>
        <Item to="/impressionen">Impressionen</Item>
        <Item to="/blog">Blog</Item>
        <Item to="/kontakt">Kontakt</Item>
      </Menu>
    </Container>
  </Component>
)

export default Navigation
