import React from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'
import presets from 'utils/presets'

const Component = styled.div`
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  justify-content: space-around;
  align-items: center;
  ${presets.Tablet} {
    display: none;
  }
  z-index: 1;
`

const Item = styled(Link)`
  margin-right: 1rem;
  text-decoration: none;
  padding: 1rem;
  color: #aaa;
  flex-shrink: 0;
  text-align: center;
`

const Navigation = () => (
  <Component>
    <Item to="/">Home</Item>
    {/*<Item to="/ueber-uns">Über uns</Item>*/}
    <Item to="/team">Team</Item>
    <Item to="/veranstaltungen">Veranstaltungen</Item>
    {/*<Item to="/impressionen">Impressionen</Item>*/}
    {/*<Item to="/blog">Blog</Item>*/}
    <Item to="/kontakt">Kontakt</Item>
  </Component>
)

export default Navigation
