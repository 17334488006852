import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import 'typeface-source-sans-pro'
import 'typeface-spectral'
import theme from '../theme'

import { Container } from 'unstyled'
import Header from './Header/index'
import Footer from './Footer/index'
import Navigation from './Navigation/Navigation'
import NavigationMobile from './Navigation/NavigationMobile'
import Helmet from 'react-helmet'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Content = Container.extend`
  flex: 1;
  margin: 0 auto;
`

const TemplateWrapper = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Wrapper key="layout-wrapper">
      <Helmet
        key="app-head"
        titleTemplate="%s | Julius Klengel Akademie"
        defaultTitle="Julius Klengel Akademie"
      >
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>
      <Header />
      <Navigation />
      <Content>{children}</Content>
      <Footer />
      <NavigationMobile />
    </Wrapper>
  </ThemeProvider>
)

export default TemplateWrapper
