import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from 'components/Layout'
import SEO from '../components/SEO'
import { Heading } from 'unstyled'

const Content = styled.div`
  max-width: 50%;
  margin: 0 auto;
`

const Component = ({ data: { markdownRemark: about } }) => (
  <Layout>
    <SEO data={{ title: 'Ueber Uns', description: about.excerpt }} />
    <Content>
      <Heading>Ueber uns</Heading>
      <div dangerouslySetInnerHTML={{ __html: about.html }} />
    </Content>
  </Layout>
)

export const query = graphql`
  query AboutUs {
    markdownRemark(frontmatter: { title: { eq: "ueber-uns" } }) {
      id
      html
      excerpt
    }
  }
`

export default Component
