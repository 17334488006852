/* Basic theme with minimal styling. Nearly unstyled.*/
const DefaultTheme = {
  fontFamily:
    'Futura PT,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Arial,sans-serif',
  fontWeight: 400,
  headingFontWeight: 400,
  primaryColor: 'rgba(0,0,0,.6)',
  secondaryColor: '#f8f8f9',
  codeFont:
    'Space Mono,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace',
  fontColor: 'rgb(46, 68, 78)',
  fontSize: '16px',
  red: '#DB2828',
  orange: '#F2711C',
  yellow: '#FBBD08',
  olive: '#B5CC18',
  green: '#21BA45',
  teal: '#00B5AD',
  blue: '#2185D0',
  violet: '#6435C9',
  purple: '#A333C8',
  pink: '#E03997',
  brown: '#A5673F',
  grey: '#767676',
  black: '#1B1C1D',
}

export default DefaultTheme
